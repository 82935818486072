export default [
    [
        '❐ 프로그래밍을 통해 상상을 현실로 이끌어내는 IT 동아리입니다.',
        [
            '멋사에 소속된 우리 모두는 멋쟁이 사자가 되기 위한 예비 사자이고,',
            '각자의 포지션을 갈고 닦아 1년 동안 멋진 프로그램을 산출해냅니다.',
        ],
        '/img/intro_img2.jpg'
    ],
    [
        '❐ 주로 프로그래밍 능력 향상과 스타트업 창업 문화 전파를 목표로 합니다.',
        [
            '멋쟁이사자처럼은 2013년에 설립되었으며,',
            '아기사자들에게 웹 개발과 프로그래밍 기초 지식을 가르칩니다.',
            '궁극적 목표는 비전공자들도 프로그래밍 기초 지식을 배워 자신만의 웹서비스를 만들어 이를 통해 꿈을 실현할 수 있도록 하는 것입니다.',
            '최근에는 이러한 기술 향상을 바탕으로 IT 창업까지 이어질 수 있도록 목표하고있습니다.',
        ],
        '/img/intro_img4.jpg'
    ],
    [
        '❐ 학생들이 직접 만드는 동아리입니다.',
        [
            '멋쟁이 사자처럼은 아기사자들이 스스로 학습하고 프로젝트를 진행하며 문제를 해결할 수 있는 능력을 기르는 것을 중요하게 여깁니다.',
            '이 과정에서 아기사자들은 실제로 사용 가능한 웹 애플리케이션을 개발하는 경험을 하게 되며,',
            '이는 아기사자들의 포트폴리오 구축에도 도움이 됩니다.',
        ],
        '/img/intro_img1.jpg'
    ],
    [
        '❐ 교육 과정은 보통 1년 단위로 운영되며, 이론 교육과 실습, 프로젝트 수행이 포함됩니다.',
        [
            '참가자들은 팀을 이루어 실제로 동작하는 웹 서비스나 애플리케이션을 만들어냅니다.',
            '이 과정에서 참가자들은 프론트엔드와 백엔드 개발뿐만 아니라,',
            '협업 도구 사용, 버전 관리 시스템(Git 등)의 사용법, 그리고 스타트업 운영과 관련된 지식도 습득하게 됩니다.',
        ],
        '/img/intro_img3.jpg'
    ],
    [
        '❐ 멋쟁이 사자처럼은 전국의 여러 대학에 지부를 두고 있습니다.',
        [
            '각 지부는 독립적으로 운영되면서도 중앙에서 제공하는 교육 커리큘럼과 지원을 받습니다.',
            '특히, 이 과정에서 우리는 중앙 해커톤에 참가하며, 다른 대학 멋사들과 경쟁할 수 있습니다.',
        ],
        '/img/intro_img5.jpg'
    ]
];