export default [
    [
        '❐ 아래 3가지 사항을 필히 준수하며 멋사 활동에 성실하게 임해야 합니다.',
        [
            '- 멋사의 정기 세션에 성실히 참석 (세션 장소와 시간은 Notion으로 안내됩니다.)',
            '- 멋사의 과제를 성실히 수행 (과제와 기한은 Notion으로 공지됩니다.)',
            '- 멋사의 팀 프로젝트 활동에 성실히 참여 (미니 프로젝트, 정기 프로젝트, 해커톤, 스터디 등)',
        ],
    ],
    [
        '❐ 멋사의 퇴출 기준은 다음과 같습니다.',
        [
            '- 경고 2회 누적 시',
            '- 정당하지 않은 사유로 세션에 참석하지 않는 경우 (횟수 당 경고 1회)',
            '- 과제를 제출하지 않거나 성실하게 수행하지 않는 경우 (해당 세션 담당 운영진의 판단에 의함)',
            '- 기타 운영진의 합치에 의하여, 해당 부원이 동아리 활동에 성실히 응하지 않거나 다른 이에게 해를 끼친다고 판단되는 경우'
        ]
    ],
    [
        '',
        [
            '성실하고 열정적으로 멋사의 활동에 임하시어'
        ]
    ],
    [
        '멋사 수료증과 함께 행복하게 1년을 마치시길 기원합니다!',
        [
            ''
        ]
    ]
];