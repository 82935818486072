export default [
    [
        '❐ 우리는 이런 Mission을 가지고 있습니다.',
        [
            '- IT 교육 기회를 제공하여 꿈꾸던 가능성을 열어준다.',
            '- IT 기술로 IT 교육의 문제점을 해결한다.',
            '- 지속 성장이 가능한 IT 커뮤니티를 구축한다.'
        ],
    ],
    [
        '❐ 우리의 첫번째 가치, 자기 주도 학습',
        [
            '스스로가 학습의 주체가 되어야 한다는 것을 기본 전제로 합니다.',
            '학습 목표를 설정하고, 필요한 지식을 탐색하며, 문제를 해결하는 과정에서 독립적인 학습 능력을 키울 수 있습니다.',
            '',
            '이를 위해 운영진들은 아기사자에게 "세션"을 통하여 기초 배경지식을 제공합니다. ',
            '이 과정속에서 아기사자는 선배 사자에게 필요한 조언을 구하며, 원활히 본인의 능력을 향상시킬 수 있습니다.'
        ],
    ],
    [
        '❐ 우리의 두번째 가치, 실천 중심 학습',
        [
            '이론만이 아니라 실제로 코드를 작성하고 프로젝트를 완성하는 실천 중심의 학습을 중요시합니다.',
            '이를 통해 실제 세계에서 발생할 수 있는 문제에 대응하고, 협업과 프로젝트 관리 경험을 쌓게 됩니다.',
        ],
    ],
    [
        '❐ 우리의 가장 큰 가치, 팀워크와 지식을 공유하는 문화',
        [
            '프로젝트, 특히 그 속에서의 팀워크는 우리 <외대 글로벌 멋사>에서 가장 중시하는 요소입니다.',
            '팀워크가 바탕이 되었을 때 그 프로젝트는 가장 빛을 발할 수 있습니다.',
            '',
            '그리고 이 과정에서 각자의 실력 향상을 위해 지식과 경험을 공유하기를 권장합니다.',
            '이를 위해 배운 내용을 다른 사람과 공유하고, 협력하여 함께 성장하는 것을 장려합니다.',
        ],
    ],
];